import * as React from 'react';
import { observable, action, computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';

import { permissionStore } from '@app/Stores/PermissionStore';
import { DateTimeService } from '@app/Services/DateTimeService';
import { appStore } from '@app/AppStore';

import AircraftPlaceholder from '@app/Components/AircraftPlaceholder/AircraftPlaceholder';

type ForbiddenProps = {
    url: string | null;
    method: string | null;
    error: string | null;
};

@observer
export class Forbidden extends React.Component<ForbiddenProps> {
    private _store: ForbiddenStore;

    constructor(props: ForbiddenProps) {
        super(props);
        makeObservable(this);
        this._store = new ForbiddenStore();
    }

    render() {

        const email = appStore.currentUser?.unique_name ? appStore.currentUser.unique_name : 'anonymous@noreply.com';
        const name = appStore.currentUser && appStore.currentUser.name ? appStore.currentUser.name : '';

        const addressing = name ? (
            <>
                {name} (<a href={'mailto:' + email}>{email}</a>)
            </>
        ) : (
            <a href={'mailto:' + email}>{email}</a>
        );
        const { method, url, error } = this.props;

        const realUserRoles = permissionStore.getRolesMatchFromEncodedRoles(appStore.realUserRoles);
        const impersonatedUserRoles = permissionStore.getRolesMatchFromEncodedRoles(appStore.impersonatedUserRoles);

        return (
            <AircraftPlaceholder pageTitle="403" mainClass="page-not-found">
                <h1 className="scene-message-red">403</h1>
                <h2 className="scene-message-red">Forbidden</h2>
                <p className="scene-message-bold">
                    Hi, {addressing} ! <br /> You don't have permission to access this page.
                </p>
                <h3 className="scene-message-additional">Technical details</h3>
                {appStore.currentUser && (
                    <p className="tal">
                        <small>
                            <strong>Principal claims:</strong> {realUserRoles}
                        </small>
                    </p>
                )}
                {appStore.impersonatedUser && appStore.impersonatedUser.personId && (
                    <p className="tal">
                        <small>
                            <strong>Impersonate person:</strong> {this._store.impersonatePersonName}
                            {appStore.impersonatedUser.impersonateAsPrincipal ? ' (as principal)' : ''}
                        </small>
                    </p>
                )}
                {appStore.impersonatedUser && appStore.impersonatedUser.roles && (
                    <p className="tal">
                        <small>
                            <strong>Impersonate claims:</strong> {impersonatedUserRoles}
                        </small>
                    </p>
                )}
                {url && (
                    <p className="tal">
                        <small>
                            <strong>Request url:</strong> {method?.toUpperCase()} {url}
                        </small>
                    </p>
                )}
                {error && (
                    <p className="tal">
                        <small>
                            <strong>Error:</strong> {error}
                        </small>
                    </p>
                )}
                {this._store.timestamp && (
                    <p className="tal">
                        <small>
                            <strong>Timestamp:</strong> {DateTimeService.format(this._store.timestamp, 'YYYY-MM-DD HH:mm:ss')}
                        </small>
                    </p>
                )}
            </AircraftPlaceholder>
        );
    }
}

class ForbiddenStore {
    @observable personName: PersonName | null = null;
    @observable timestamp: Date | null = null;

    constructor() {
        makeObservable(this);
        this._getTimestamp();
    }

    @computed
    get impersonatePersonName(): string {
        if (appStore.impersonatedUser && appStore.impersonatedUser.personId) {
            if (this.personName) return `${this.personName.firstName} ${this.personName.lastName} (${this.trimStartEndZero(appStore.impersonatedUser.personId)})`;
            else return appStore.impersonatedUser.personId;
        }

        return '';
    }

    trimStartEndZero(personId: string) {
        return personId.replace(new RegExp('^[0, -]+'), '').replace(new RegExp('[0, -]+$'), '');
    }

    private _getTimestamp() {
        this._setTimestamp(DateTimeService.now());
    }

    @action
    setPersonName(firstName?: string, lastName?: string) {
        this.personName = { firstName: firstName ? firstName : '', lastName: lastName ? lastName : '' };
    }

    @action
    private _setTimestamp(timestamp: Date) {
        this.timestamp = timestamp;
    }
}

type PersonName = {
    firstName: string;
    lastName: string;
};
