import React, { PropsWithChildren } from 'react';
import DocumentTitle from 'react-document-title';

type AircraftPlaceholderProps = PropsWithChildren & {
    pageTitle: string;
    mainClass: string;
    sceneMsgClass?: string;
};

export default class AircraftPlaceholder extends React.PureComponent<AircraftPlaceholderProps> {
    render() {
        const { pageTitle, mainClass, sceneMsgClass, children } = this.props;
        return (
            <div className={mainClass}>
                <DocumentTitle title={pageTitle} />
                <div className="scene">
                    <span className="cloud cloud--small-1" />
                    <span className="cloud cloud--small-2" />
                    <span className="cloud cloud--small-3" />
                    <span className="cloud cloud--medium-1" />
                    <span className="cloud cloud--medium-2" />
                    <span className="cloud cloud--large-1" />
                    <span className="cloud cloud--large-2" />
                    <img className="plane" src={'/images/plane.svg'} alt="" />
                    <div className={'scene-message ' + sceneMsgClass}>{children}</div>
                </div>
            </div>
        );
    }
}
