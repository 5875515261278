import { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';

import { globalAjaxLoaderStore } from './Stores/GlobalAjaxLoaderStore';
import { router } from './router/router';
import './App.scss';
import { signalRService } from './Services/SignalRService';

export const App = () => {
    let devicePixelRatio: number = 0;
    const resizeHandler = () => {
        if (!devicePixelRatio || devicePixelRatio !== window.devicePixelRatio) {
            devicePixelRatio = window.devicePixelRatio;

            const scrollDiv = document.createElement('div');
            scrollDiv.style.width = '100px';
            scrollDiv.style.height = '100px';
            scrollDiv.style.overflowY = 'scroll';
            scrollDiv.style.visibility = 'hidden';
            document.body.appendChild(scrollDiv);

            const scrollBarWidth = (scrollDiv.offsetWidth - scrollDiv.clientWidth);

            document.body.removeChild(scrollDiv);
            document.body.style.setProperty('--scrollbar-width', scrollBarWidth + 'px');
        }
    };

    const hideAppLoader = () => {
        const loader = document.getElementById('react-app-loader');
        const message = document.getElementById('react-app-loader-message');
        if (loader && message) {
            globalAjaxLoaderStore.appLoaderElement = loader;
            globalAjaxLoaderStore.appLoaderMessageElement = message;
            loader.addEventListener(
                'transitionend',
                () => {
                    if (loader.classList.contains('hide')) {
                        loader.classList.add('invisible');
                    }
                },
                true
            );
        }
        resizeHandler();
    };

    useEffect(() => {
        signalRService.ref();
        hideAppLoader();
        window.addEventListener('resize', resizeHandler);
        resizeHandler();
    }, []);

    return (
        <div className="App">
            <RouterProvider router={router}/>
        </div>
    );
};