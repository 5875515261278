import { computed, makeObservable, observable } from "mobx";

import { appStore } from "@app/AppStore";
import { ApiUrls } from "@app/AppConstants/ApiUrls";
import { UserPermissions } from '@app/Classes/AzureAuth';
import { PredefinedRoleModel } from "@app/Models/WebApiModels";
import ApiService from "@app/Services/ApiService";

class PermissionStore {
    @observable roles: PredefinedRoleModel[] = [];

    constructor() {
        makeObservable(this);
    }

    @computed
    public get currentUserPermissions(): number[] {
        return this.getPermissionIdsFromEncodedRoles(appStore.impersonatedUserRoles) || this.getPermissionIdsFromEncodedRoles(appStore.realUserRoles) || [];
    }

    public async loadRoles() {
        const { data } = await ApiService.getTypedData<PredefinedRoleModel[]>(ApiUrls.PermissionRoleUrl, null);
        this.roles = data;
    }

    public encodeBytesToBase64(bytes: number[]) {
        return bytes.length ? '1' + btoa(String.fromCharCode.apply(null, bytes)) : '';
    }

    public decodeBase64ToBytes(encodedStr: string) {
        if (!encodedStr.startsWith('1')) return;

        const base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
        const base64Str = encodedStr.slice(1);
        const isValid = base64regex.test(base64Str);

        if (isValid) {
            return atob(base64Str).split('').map(c => c.charCodeAt(0));
        }
    }

    public convertPermissionIdsToBytes(ids?: number[]) {
        if (!ids || !ids.length) return [];

        const maxId = Math.max(...ids);
        const bytesCount = Math.ceil(maxId / 8);
        const bytes = new Uint8Array(bytesCount);
        ids.forEach(id => {
            const byteIndex = Math.floor((id - 1) / 8);
            const bitIndex = ((id - 1) - byteIndex * 8);
            bytes[byteIndex] = bytes[byteIndex] | (128 >>> bitIndex);
        });

        return Array.from(bytes);
    }

    public convertBytesToPermissionIds(bytes?: number[]) {
        if (!bytes) return [];

        const ids: number[] = [];
        const bitesCount = bytes.length * 8;
        for (let i = 0; i < bitesCount; i++) {
            const byteIndex = Math.floor(i / 8);
            const bitIndex = (i - byteIndex * 8);
            const isBitSet = (bytes[byteIndex] & (128 >>> bitIndex)) !== 0;
            if (isBitSet) {
                const permissionId = i + 1;
                ids.push(permissionId);
            }
        }

        return ids;
    }

    public getPermissionIdsFromEncodedRoles(encodedStrings: string[]) {
        if (!encodedStrings.length) return;

        let permissionIds: number[] = [];
        for (let str of encodedStrings) {
            const bytes = this.decodeBase64ToBytes(str);
            const ids = this.convertBytesToPermissionIds(bytes);
            permissionIds = permissionIds.concat(ids);
        }

        return permissionIds;
    }

    public getRolesMatchFromEncodedRoles(encodedRoles: string[]) {
        const permissionIds = this.getPermissionIdsFromEncodedRoles(encodedRoles);
        const rolesMatch = this.getRolesMatch(permissionIds)?.text;

        return rolesMatch || '';
    }

    public getRolesMatch(selectedPermissions?: number[]) {
        if (!selectedPermissions) return null;

        const matchedRoles: { role: PredefinedRoleModel, matches: number, kpi: number }[] = [];
        this.roles.forEach(role => {
            if (!role.permissionIds) return;
            const { permissionIds } = role;

            const minKpi = 50;
            const permissionMatchesCount = permissionIds.filter(p => selectedPermissions.includes(p)).length;
            let kpi = 0;
            if (!!permissionMatchesCount && !!permissionIds.length) {
                const denominator = selectedPermissions.length > permissionIds.length ? selectedPermissions.length : permissionIds.length;
                kpi = (permissionMatchesCount / denominator) * 100;
            }
            if (permissionMatchesCount > 0 && kpi >= minKpi) {
                matchedRoles.push({ role, matches: permissionMatchesCount, kpi });
            }
        });

        const matchedRole = matchedRoles.sort((a, b) => {
            if (b.matches > a.matches) {
                return 1;
            } else if (b.matches === a.matches) {
                return b.kpi - a.kpi;
            } else {
                return -1;
            }
        })[0];

        if (matchedRole?.role.permissionIds) {
            const { role: { permissionIds: rolePermissions }, matches } = matchedRole;
            const isExtraSelected = selectedPermissions.length > matches;
            const isExtraRole = rolePermissions.length > matches;

            let result = `${matchedRole.role.codeName} role`;
            let resultSql = `/* ${matchedRole?.role.codeName} changes */\n`;
            if (isExtraSelected) {
                const extraSelectedPermissions = selectedPermissions
                    .filter(p => !rolePermissions?.includes(p))
                    .map(p => UserPermissions[p]);

                result = `${result} + ${extraSelectedPermissions.join(' + ')}`;

                const extraSelectedPermissionsSql = selectedPermissions
                    .filter(p => !rolePermissions?.includes(p))
                    .map(p => `(${matchedRole.role.id} /* ${matchedRole.role.codeName} */, ${p} /* ${UserPermissions[p]} */)`);

                resultSql += `INSERT INTO [sec].[PredefinedRolePermission] ([RoleId], [PermissionId]) \nVALUES \n${extraSelectedPermissionsSql.join(',\n')}\nGO\n`;
            }

            if (isExtraRole) {
                const extraRolePermissions = rolePermissions
                    .filter(p => !selectedPermissions.includes(p))
                    .map(p => UserPermissions[p]);

                result = `${result} excluding ${extraRolePermissions.join(', ')}`;

                const extraRolePermissionsSql = rolePermissions
                    .filter(p => !selectedPermissions.includes(p))
                    .map(p => `DELETE FROM [sec].[PredefinedRolePermission] WHERE [RoleId] = ${matchedRole.role.id} /* ${matchedRole.role.codeName} */ AND [PermissionId] = ${p} /* ${UserPermissions[p]} */\nGO\n`);

                resultSql += extraRolePermissionsSql.join('');
            }

            return {
                text: result,
                sql: (isExtraSelected || isExtraRole) ? resultSql : null
            };
        }

        const customRole = selectedPermissions.map(p => UserPermissions[p]);
        return {
            text: `${customRole.join(', ')}`,
            sql: null
        };
    }
}

export const permissionStore = new PermissionStore();