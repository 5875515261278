import { observable, action } from 'mobx';
import { UrlService } from '@services/UrlService.ts';
import { ComponentUrls, GlobalQueryParams } from '@app/AppConstants';
import { getEncodedToken } from '@helpers/JWTHelper.ts';
import { UserProfileDTO } from './Models/User';

class AppStore {
    @observable isLoading: boolean = false;
    private _currentUser: UserProfileDTO | null = null;
    signalRConnectionId?: string;

    public get currentToken (): string {
        return this.getValue<string>('currentToken') || '';
    }

    public get currentUser (): UserProfileDTO | null {
        if (!this._currentUser) {
            this._currentUser = this.getValue<UserProfileDTO>('currentUser');
        }

        return this._currentUser;
    }

    public get impersonatedUserRoles(): string[] {
        return this.impersonatedUser?.roles || [];
    }

    public get realUserRoles(): string[] {
        return this.currentUser?.roles || [];
    }

    @action
    isAuthorized (): boolean {
        return !!(this.currentToken && this.currentUser);
    }

    @action
    clearUserData () {
        localStorage.removeItem('currentUser');
        localStorage.removeItem('currentToken');
    }

    @action
    setValue<T> (key: string, value: T) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    public get impersonatedUser (): IImpersonationModel | null {
        return this.impersonatedUsersStack.pop() || null;
    }

    public get impersonatedUsersStack () {
        return this.getValue<IImpersonationModel[]>('impersonatedUsers') || [];
    }

    public get currentTokenObj () {
        return this._parseJwtToken(this.currentToken);
    }

    private _parseJwtToken (token: string): JwtToken | null {
        if (!token) return null;

        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(
            atob(base64).split('').map((c: string) => {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join('')
        );

        return JSON.parse(jsonPayload);
    }

    @action
    getValue<T> (key: string): T | null {
        const value = localStorage.getItem(key);
        return value ? (JSON.parse(value) as T) : null;
    }

    public getQueryStringAuthParams (opts?: { single?: boolean, token?: boolean }) {
        const parameters: string[] = [];

        const impersonateAsPersonId = UrlService.getParam(GlobalQueryParams.impersonateAsPerson);
        if (impersonateAsPersonId) {
            parameters.push(ComponentUrls.QsImpersonatedPersonId + '=' + impersonateAsPersonId);
        }

        const viewPersonId = UrlService.getParam(GlobalQueryParams.viewPersonId);
        if (viewPersonId) {
            parameters.push(ComponentUrls.QsViewPersonId + '=' + viewPersonId);
        }

        if (opts?.token) {
            const encodedToken = getEncodedToken(appStore.currentToken);
            parameters.push(ComponentUrls.QsToken + '=' + encodeURIComponent(encodedToken));
        }

        if (!parameters.length) {
            return '';
        }

        return (opts?.single ? '?' : '&') + parameters.join('&');
    }

}

export interface IImpersonationModel {
    personId?: string;
    name?: string;
    roles?: Array<string>;
    impersonateAsPrincipal?: boolean;
    email?: string;
}

type JwtToken = {
    aio: string;
    amr: string[];
    aud: string;
    exp: number;
    family_name: string;
    given_name: string;
    iat: number;
    ipaddr: string;
    iss: string;
    name: string;
    nbf: number;
    nonce: string;
    oid: string;
    rh: string;
    roles: string[];
    sub: string;
    tid: string;
    unique_name: string;
    upn: string;
    uti: string;
    ver: string;
};

export const appStore = new AppStore();