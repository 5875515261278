export class GlobalQueryParams {
    public static readonly impersonateAsPerson: string = 'impersonateAsPerson';
    public static readonly viewPersonId: string = 'viewPersonId';
}

export class RequestToolQueryParams {
    public static readonly requestId: string = 'requestId';
    public static readonly vacationYearId: string = 'vacationYearId';
    public static readonly tzvYearId: string = 'tzvYearId';
}
