import * as React from 'react';
import { observer } from 'mobx-react';
import { action, makeObservable, observable, when } from 'mobx';
import * as PopperJS from '@popperjs/core';
import ReactDOM from 'react-dom';
import { PropsWithChildren } from 'react';

const CONTAINER_ID = 'tooltip-container';

export class ToolTipContainer extends React.Component {
    render () {
        return <div id={CONTAINER_ID}/>;
    }
}

export enum ToolTipItemMode {
    MouseHover = 'MouseHover',
    MouseClick = 'MouseClick'
}

type ToolTipItemProps = PropsWithChildren & {
    targetId: string;
    text?: string | JSX.Element;
    className?: string;
    placement?: PopperJS.Placement;
    mode?: ToolTipItemMode,
    asyncComputedText?: () => Promise<string | JSX.Element>;
};

@observer
export class ToolTipItem extends React.Component<ToolTipItemProps> {
    @observable private _showPortal: boolean = false;
    @observable private _tooltipInstance: PopperJS.Instance | null = null;
    @observable private _tooltipNumber: number = 0;
    @observable private _tooltipsCounter: number = 0;
    @observable private _computedText?: string | JSX.Element;
    private _containerObserver: MutationObserver | null = null;
    private _containerElement: HTMLElement | null = document.getElementById(CONTAINER_ID);
    private _tooltipRef: React.RefObject<HTMLDivElement> = React.createRef();
    private _tooltipArrowRef: React.RefObject<HTMLDivElement> = React.createRef();
    private _targetElement: HTMLElement | null = null;
    private _timer: number | null = null;
    private _popperMouseOver: boolean = false;

    constructor (props: ToolTipItemProps) {
        super(props);
        makeObservable(this);
    }

    componentDidMount () {
        this._targetElement = document.getElementById(this.props.targetId);
        if (this._targetElement) {
            if (!this.props.mode || this.props.mode === ToolTipItemMode.MouseHover) {
                this._targetElement.addEventListener('mouseenter', this._onContainerMouseOver);
                this._targetElement.addEventListener('mouseleave', this._onContainerMouseOut);
            } else {
                document.addEventListener('click', this._onDocumentMouseClick, true);
            }
            this._createContainerChangeDetector();
        }
    }

    componentWillUnmount () {
        if (this._targetElement) {
            if (!this.props.mode || this.props.mode === ToolTipItemMode.MouseHover) {
                this._targetElement.removeEventListener('mouseenter', this._onContainerMouseOver);
                this._targetElement.removeEventListener('mouseleave', this._onContainerMouseOut);
            } else {
                document.removeEventListener('click', this._onDocumentMouseClick);
            }
            this._containerObserver?.disconnect();
        }
    }

    _popperMouseEnter = () => {
        this._popperMouseOver = true;
    };
    _popperMouseLeave = () => {
        this._popperMouseOver = false;
        when(
            () => this._tooltipsCounter === this._tooltipNumber,
            () => {
                this._closePopper();
            }
        );
    };

    @action.bound
    private _createPopper () {
        if (!this._targetElement || !this._tooltipRef.current) return;
        const { placement } = this.props;
        this._tooltipInstance = PopperJS.createPopper(this._targetElement, this._tooltipRef.current, {
            placement: placement || 'right',
            modifiers: [
                {
                    name: 'arrow',
                    options: {
                        element: this._tooltipArrowRef.current
                    }
                },
                {
                    name: 'events',
                    phase: 'main',
                    enabled: true,
                    effect: (eff) => {
                        eff.state.elements.popper.addEventListener('mouseenter', this._popperMouseEnter);
                        eff.state.elements.popper.addEventListener('mouseleave', this._popperMouseLeave);
                        return () => {
                            eff.state.elements.popper.removeEventListener('mouseenter', this._popperMouseEnter);
                            eff.state.elements.popper.removeEventListener('mouseleave', this._popperMouseLeave);
                        };
                    }
                }
            ]
        });
        this._tooltipNumber = this._containerElement?.childElementCount || 0;
    }

    @action
    private _createContainerChangeDetector () {
        this._containerObserver = new MutationObserver((mutationList) => {
            for (const mutation of mutationList) {
                if (mutation.type === 'childList') {
                    this._tooltipsCounter = mutation.target.childNodes.length;
                }
            }
        });
        this._containerElement && this._containerObserver?.observe(this._containerElement, { childList: true });
    }

    @action.bound
    private _destroyPopper () {
        if (this._tooltipInstance) {
            this._tooltipInstance.destroy();
            this._tooltipInstance = null;
        }
    }

    private _closePopper () {
        this._stopTimer();
        this._timer = window.setTimeout(() => {
            if (!this._popperMouseOver) {
                this._hide();
            }
        }, 100);
    }

    @action.bound
    private _onContainerMouseOut = () => {
        this._closePopper();
        this._computedText = undefined;
    };

    @action.bound
    private async _onContainerMouseOver () {
        this._stopTimer();
        this._showPortal = true;
        this._timer = window.setTimeout(this._createPopper, 250);
        this._computedText = await this.props.asyncComputedText?.();
    }

    @action.bound
    private _onDocumentMouseClick (event: MouseEvent) {
        const target = event.target as Element;
        const popper = target.closest('.popper');

        if (this._targetElement?.id === target.id && !this._showPortal) {
            this._stopTimer();
            this._showPortal = true;
            this._timer = window.setTimeout(this._createPopper, 250);
        } else if (!popper) {
            this._hide();
        }
    }

    @action.bound
    private _hide () {
        this._stopTimer();
        this._destroyPopper();
        this._showPortal = false;
    }

    private _stopTimer () {
        if (this._timer) {
            window.clearTimeout(this._timer);
            this._timer = null;
        }
    }

    render () {
        const { className, children, text, mode } = this.props;
        const cls = ['tooltip popper'];
        if (mode === ToolTipItemMode.MouseClick)
            cls.push('tooltip-selectable');
        className && cls.push(className);
        this._tooltipInstance && cls.push('show');
        return (
            <ToolTipPortal showPortal={this._showPortal}>
                <div className={cls.join(' ')} ref={this._tooltipRef}>
                    {this._tooltipInstance && (
                        <div className="tooltip-inner">
                            {this._computedText}
                            {text}
                            {children}
                        </div>
                    )}
                    <div className="arrow" data-popper-arrow ref={this._tooltipArrowRef}/>
                </div>
            </ToolTipPortal>
        );
    }
}

type ToolTipPortalProps = PropsWithChildren & {
    showPortal: boolean;
};

class ToolTipPortal extends React.Component<ToolTipPortalProps> {
    private _portalContainer: HTMLElement | null = document.getElementById(CONTAINER_ID);

    render () {
        return this._portalContainer && this.props.showPortal ? ReactDOM.createPortal(this.props.children, this._portalContainer) : null;
    }
}
