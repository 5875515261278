import * as React from 'react';
import { permissionStore } from '@app/Stores/PermissionStore';
import { appStore } from '../AppStore';

export interface IWindowAdal extends Window {
    adal?: {
        redirectToLogin (): void;
        redirectToLogout (): void;
    };
}

export class AzureAuth extends React.Component<{}, {}> {

    static logOut () {
        appStore.clearUserData();
        const appWindow: IWindowAdal = window;
        appWindow.adal && appWindow.adal.redirectToLogout();
    }

    static login () {
        appStore.clearUserData();
        const appWindow: IWindowAdal = window;
        appWindow.adal && appWindow.adal.redirectToLogin();
    }

    public static hasAccess(permissions: number | number[]) {
        const hasAdmin = permissionStore.currentUserPermissions.includes(UserPermissions.AdminToolsWrite);
        if (hasAdmin) return true;

        if (typeof permissions === 'number') {
            return permissionStore.currentUserPermissions.indexOf(permissions) !== -1;
        }

        if (Array.isArray(permissions)) {
            for (const permission of permissions) {
                if (permissionStore.currentUserPermissions.indexOf(permission) !== -1) {
                    return true;
                }
            }
        }
        return false;
    }
}

export enum UserPermissions {
    AdminToolsWrite = 1,
    AdminToolsRead = 2,
    AdminToolsSafe = 3,
    FullPermissionImpersonate = 5,
    ToiRead = 10,
    ToiWrite = 11,
    EmailsRead = 49
}