import * as React from 'react';
import { createBrowserRouter, RouteObject } from 'react-router-dom';
import { BaseLayout } from '@components/BaseLayout';
import { Loading } from '../Components/Loading/Loading';
const HomePage = React.lazy(() => import('@pages/HomePage/HomePage'));
const HealthBoard = React.lazy(() => import('@pages/HealthBoard/HealthBoard'));
const FlightSchedulePage = React.lazy(() => import('@pages/FlightSchedulePage/FlightSchedulePage'));
const JobList = React.lazy(() => import('@pages/Job/JobList'));

const routes = [
    {
        path: '/',
        element: <BaseLayout/>,
        children: [
            {
                path: '/',
                element: <React.Suspense fallback={<Loading fullpage />}><HomePage/></React.Suspense>,
            },
            {
                path: 'health-board',
                element: <React.Suspense fallback={<Loading fullpage />}><HealthBoard/></React.Suspense>,
            },
            {
                path: 'flight-schedule',
                element: <React.Suspense fallback={<Loading fullpage />}><FlightSchedulePage /></React.Suspense>,
            },
            {
                path: 'jobs',
                element: <React.Suspense fallback={<Loading fullpage />}><JobList /></React.Suspense>,
            },
        ],
    },
] as RouteObject[];

export const router = createBrowserRouter(routes);
