import * as React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export class NotificationContainer extends React.Component {
    render () {
        return <ToastContainer
            position="bottom-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
            closeButton
        />;
    }
}

export class NotificationHandler {
    public static showError (message: string, title: string = 'Error') {
        const content = (
            <div>
                <div>{title}</div>
                <div>{message}</div>
            </div>
        );
        toast.error(content);
    }

    public static showInfo (message: string, title: string = 'Info') {
        const content = (
            <div>
                <div>{title}</div>
                <div>{message}</div>
            </div>
        );
        toast.info(content);
    }

    public static showWarning (message: string, title: string = 'Warning') {
        const content = (
            <div>
                <div>{title}</div>
                <div>{message}</div>
            </div>
        );
        toast.warning(content);
    }

    public static showSuccess (message: string, title: string = 'Success') {
        const content = (
            <div>
                <div>{title}</div>
                <div>{message}</div>
            </div>
        );
        toast.success(content);
    }

    public static suppressError (message: string) {
        const excludeErrors: { pattern: string, reason: string }[] = [{
            pattern: '__gCrWeb',
            //https://stackoverflow.com/questions/26483541/referenceerror-cant-find-variable-gcrweb
            reason: 'Edge on iOS bug'
        }, {
            pattern: 'instantSearchSDKJSBridgeClearHighlight',
            //https://stackoverflow.com/questions/69261499/what-is-instantsearchsdkjsbridgeclearhighlight
            reason: 'Edge on iOS bug'
        }];

        for (const p of excludeErrors) {
            if (message && message.indexOf(p.pattern) !== -1) {
                //do not report
                return true;
            }
        }

        return false;
    }

}