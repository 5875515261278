import { useEffect, useMemo } from 'react';
import { action, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import { Outlet, useLocation } from 'react-router-dom';

import { emitter } from '@app/Services/EventEmitter';
import { Forbidden } from '@app/Pages/Forbidden/Forbidden';
import { Header } from '@app/Components/Header/Header';
import './_base-component.scss';

export const BaseComponent = observer(() => {
    const routeStore = useMemo(() => new RouteStore(), []);
    const { hasErrors, url, method, error } = routeStore;
    const { pathname } = useLocation();

    useEffect(() => {
        routeStore.clearError();
    }, [pathname])

    return (
        <div className="base-component">
            <Header />
            <div className="base-content">
                {hasErrors
                    ? <Forbidden url={url} method={method} error={error} />
                    : <Outlet />
                }
            </div>
        </div>
    );
});

class RouteStore {
    @observable hasErrors: boolean = false;
    @observable url: null | string = null;
    @observable method: null | string = null;
    @observable error: null | string = null;

    constructor() {
        makeObservable(this);
        emitter.on('notify-error-403', this.onErrorHandler);
    }

    unload() {
        emitter.off('notify-error-403', this.onErrorHandler);
    }

    @action.bound
    onErrorHandler(details: { config?: { url: string, method: string }, response?: { data?: { error: string } } }) {
        this.hasErrors = true;
        this.url = details.config?.url || null;
        this.method = details.config?.method || null;
        this.error = details.response?.data?.error || null;
    }

    @action.bound
    clearError() {
        this.hasErrors = false;
        this.url = null;
        this.method = null;
        this.error = null;
    }
}
