export class ApiUrls {
    public static readonly ConfigurationUrl: string = '/api/configuration';

    public static readonly MonitoringUrl: string = '/api/monitoring';
    public static readonly ServiceQueueStatisticsUrl: string = '/api/service/input-queue-statistics';
    public static readonly ServiceDownloadDeadlettersUrl: string = '/api/service/download-deadletters';
    public static readonly MessageStatisticsUrl: string = '/api/service/message-statistics';
    public static readonly ServiceDownloadFullLogsUrl: string = '/api/service/download-full-logs';
    public static readonly ServiceDatesWithLogsUrl: string = '/api/service/logs-dates';
    public static readonly ServiceDownloadMessagesUrl: string = '/api/service/download-messages';
    public static readonly ServiceDownloadDayLogsUrl: string = '/api/service/download-day-logs';
    public static readonly ServiceMessagesDatesUrl: string = '/api/service/messages-dates';
    public static readonly ServiceMessagesTagsUrl: string = '/api/service/message-tags';
    public static readonly ServiceMessagesLabelsUrl: string = '/api/service/message-labels';
    public static readonly SelfTestDatabaseUrl: string = '/api/selftest/database';
    public static readonly SelfTestJobsUrl: string = '/api/selftest/jobs';
    public static readonly ServiceRestartSchedulerUrl: string = '/api/service/restart-scheduler';
    public static readonly SelfTestSignalRUrl: string = '/api/selftest/signalr';
    public static readonly PingUrl: string = '/api/dev/ping';

    public static readonly AircraftsUrl: string = '/api/aircraft';
    public static readonly MaintenancesUrl: string = '/api/aircraft/maintenance';
    public static readonly FlightsFullUrl: string = '/api/flight';
    public static readonly FlightDecodeUrl: string = '/api/flight/decode';
    public static readonly FlightMessageHistoryUrl: string = '/api/flight/messages';

    public static readonly TempFileUrl: string = '/api/tempfile';
    public static readonly ToiAttachmentUrl: string = '/api/toi/attachment';
    public static readonly ToiPdfUrl: string = '/api/toi/pdf';

    public static readonly JobList: string = '/api/job/list';
    public static readonly JobStopUrl: string = '/api/job/stop';
    public static readonly JobStartUrl: string = '/api/job/start';
    public static readonly JobParametersUrl: string = '/api/job/parameters';
    public static readonly JobTriggerCreateUrl: string = '/api/job/create';
    public static readonly JobTriggerDeleteUrl: string = '/api/job/delete';
    public static readonly CheckTriggerNameUrl: string = '/api/job/check-trigger-name';
    public static readonly JobCronValidation: string = '/api/job/cron/validation';

    public static readonly PermissionUrl: string = '/api/permission';
    public static readonly PermissionRoleUrl: string = '/api/permission/role';
}
